html,
body,
#root {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

/* Firefox */
html {
  scrollbar-color: #9ddac6 #f1f1f1;
  scrollbar-width: thin;
}

/* WebKit and Chromiums */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #ffd371;
  border-radius: 5px;
}

body {
  font-family: "Inconsolata", monospace, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: #f1f1f1;
  scrollbar-color: #ffd371;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
